import React from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';

const FooterComponent = () => {
    return (
        <Box sx={{ backgroundColor: '#1d374f', color: '#ecf0f1', py: 4, borderTop: '1px solid #ffffff' }}>
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 4, // Adds space around the links
                    pt: 2, // Adds some padding at the top of the content inside the footer
                }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Contact
                        </Typography>
                        <List sx={{ padding: 0, textAlign: 'left' }}>
                            <ListItem sx={{ justifyContent: 'center', padding: 0 }}>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Email:</span>
                                    <Link href="mailto:napthalineg@gmail.com" sx={{ color: '#1abc9c', ml: 1 }}>
                                        napthalineg@gmail.com
                                    </Link>
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ justifyContent: 'center', padding: 0 }}>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <span>LinkedIn:</span>
                                    <Link href="https://www.linkedin.com/in/napthalinegerard" sx={{ color: '#1abc9c', ml: 1 }}>
                                        napthalinegerard
                                    </Link>
                                </Typography>
                            </ListItem>
                            <ListItem sx={{ justifyContent: 'center', padding: 0 }}>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Phone:</span>
                                    <Link sx={{ color: '#1abc9c', ml: 1 }}>
                                        +33 6100 569 51
                                    </Link>
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Typography variant="body2" sx={{ textAlign: 'center', mt: 4 }}>
                    &copy; {new Date().getFullYear()} Napthaline Gerard. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default FooterComponent;
