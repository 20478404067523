import * as React from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import HomeComponent from './HomeComponent';
import { click } from '@testing-library/user-event/dist/click';


import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import Tooltip from '@mui/material/Tooltip';
import FooterComponent from './FooterComponent';
import { Stack } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';


const drawerWidth = 240;
const navItems = ['Home', 'About', 'Resume', 'Publications', 'Contact'];

const HeaderComponent = ({ window, onItemClick, onItemClick2 }) => {


    const navigate = useNavigate();

    // const { window, onItemClick } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const aboutRef = React.useRef(null);

    const handleButtonClick = (clickedItem) => {
        onItemClick(clickedItem);
        // alert(clickedItem)
    };

    const handlemobileButtonClick = (clickedItem2) => {
        // console.log('====================================');
        // console.log(clickedItem2);
        // console.log('====================================');
        onItemClick2(clickedItem2);
    };



    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleRoute = (page) => {
        // window.open(page, '_blank');
    };


    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //         <Typography variant="h6" sx={{ my: 2, textTransform: "capitalize" }}>
    //             N{" "}a{" "}p{" "}t{" "}h{" "}l{" "}i{" "}n{" "}e{" "}
    //         </Typography>
    //         <Divider />
    //         <List>
    //             {navItems.map((item) => (
    //                 <ListItem key={item} disablePadding >
    //                     <ListItemButton sx={{ textAlign: 'center' }} onClick={() => handlemobileButtonClick(item)}>
    //                         <ListItemText primary={item} />
    //                     </ListItemButton>
    //                 </ListItem>
    //             ))}
    //         </List>
    //     </Box>
    // );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', height: "70px", backgroundColor: "#1d374f" }} >
            <CssBaseline />
            <AppBar component="nav" sx={{ height: "70px", backgroundColor: "#1d374f" }}>
                <Toolbar>
                    {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Stack sx={{ width: "100%", p: "10%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

                        <Stack spacing={6} direction="row">
                            {/* <Tooltip title="Git" placement="bottom">
                                <IconButton sx={{ color: "white" }} onClick={() => handleRoute("")}>
                                    <GitHubIcon className="hero-icons" />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title="LinkedIn" placement="bottom">
                                <a href="https://www.linkedin.com/in/napthalinegerard?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer" >


                                    <IconButton data-aos="zoom-out" data-aos-duration="1000" sx={{ color: "white" }} >
                                        <LinkedInIcon className="hero-icons" />
                                    </IconButton>
                                </a>

                            </Tooltip>
                            <Tooltip title="Email" placement="bottom">
                                <a href="mailto:napthalineg@gmail.com" target="_blank" rel="noopener noreferrer">
                                    <IconButton data-aos="zoom-out" data-aos-duration="1500" sx={{ color: "white" }} >
                                        <EmailIcon className="hero-icons" />
                                    </IconButton>
                                </a>
                            </Tooltip>
                            <Tooltip title="Insta" placement="bottom">
                                <a href="https://www.instagram.com/napthaline_gerard04?utm_source=qr&igsh=dGJubXlubDlkajV4" target="_blank" rel="noopener noreferrer">

                                    <IconButton sx={{ color: "white" }} >
                                        <InstagramIcon data-aos="zoom-out" data-aos-duration="2000" className="hero-icons" />
                                    </IconButton>
                                </a>
                            </Tooltip>
                            {/* <Tooltip title="Twitter" placement="bottom">
                                <IconButton sx={{ color: "white" }} onClick={() => handleRoute("")}>
                                    <XIcon className="hero-icons" />
                                </IconButton>
                            </Tooltip> */}
                        </Stack>

                        <Stack direction="row" spacing={2} sx={{
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: "flex" }
                        }}
                        >
                            <Tooltip title="Whatsapp" placement="bottom">
                                <LocalPhoneIcon data-aos="zoom-out" data-aos-duration="1000" className='hero-icons' sx={{ color: "white" }} />
                            </Tooltip>
                            <Typography data-aos="zoom-out" data-aos-duration="1000" sx={{ color: "white", fontFamily: `'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif`, textDecoration: "none" }}>
                                +33 6100 569 51
                            </Typography>

                        </Stack>

                    </Stack>
                </Toolbar>
            </AppBar>
            <nav>
                {/* <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },  // Changed from 'sm' to 'md'
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer> */}
            </nav>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />

            </Box>
        </Box>
    );
}


export default HeaderComponent;