import "./Css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderComponent from './Component/HeaderComponent';
import HomeComponent from './Component/HomeComponent';
import FooterComponent from './Component/FooterComponent';
import LandingPage from "./Screens/LandingPage";


const App = () => {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage></LandingPage>}></Route>
      </Routes>

    </BrowserRouter>
  );
}
export default App;
