// LandingPage.js
import React, { useState } from 'react';
import HeaderComponent from '../Component/HeaderComponent';
import HomeComponent from '../Component/HomeComponent';
import FooterComponent from '../Component/FooterComponent';

const LandingPage = () => {
    const [clickedItem, setClickedItem] = useState(null);

    const handleItemClick = (item) => {
        setClickedItem(item);
    };

    const [clickedItem2, setClickedItem2] = useState(null);

    const handleItemClick2 = (item2) => {
        // console.log(item2);
        setClickedItem2(item2);
    };

    return (
        <div className='parent-container'>


            <HeaderComponent onItemClick={handleItemClick} onItemClick2={handleItemClick2} />

            <HomeComponent clickedItem={clickedItem} clickedItem2={clickedItem2} />
        </div>
    );
};

export default LandingPage;
